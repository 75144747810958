import { createElement } from 'react';
import { NextRouter } from 'next/router';
import Link from 'next/link';

import { getFormattedDate, getImage, makeSlug } from '@/utils/helper';

interface DataTransformConfig {
    size?: ListItemSize;
    style?: ListItemStyle;
    onPress?: (args?: Record<string, unknown>) => void;
}

export const transformFestivalResults = (
    festivals: Festival[],
    router: NextRouter,
    config?: DataTransformConfig
): TileProps[] => {
    return (
        festivals?.map((festival: Festival) => {
            const subTitle: (string | GenericElement)[] = [];
            const venue = festival?.venue;

            if (venue) {
                if (venue?.city) {
                    const link = () =>
                        createElement(
                            Link,
                            {
                                href: `/locations/${venue.country.slug}/${makeSlug(venue.city)}`,
                                className: '--underline',
                            },
                            venue.city
                        );
                    subTitle.push(link);
                }

                if (venue?.country?.name) {
                    const link = () =>
                        createElement(
                            Link,
                            {
                                href: `/locations/${venue.country.slug}`,
                                className: '--underline',
                            },
                            venue.country.name
                        );
                    subTitle.push(link);
                }
            }

            return {
                id: festival.id,
                type: 'festival',
                title: festival.name,
                slug: festival?.slug,
                shareUrl: `/festivals/${festival?.slug}`,
                subTitle: subTitle,
                summary: getFormattedDate(festival?.upcomingDate),
                image: getImage(festival.thumbnail) ?? undefined,
                website: !festival.pastFestival ? festival?.website : undefined,
                compare: festival?.compare,
                inUserCrate: festival?.inUserCrate,
                size: config?.size ?? 'default',
                style: config?.style ?? 'box',
                favoriteArtistCount: festival?.favoriteArtistCount,
                hasFavoriteArtists: festival?.hasFavoriteArtists,
                favoriteArtists: festival?.favoriteArtists,
                genres: festival?.genres,
                getTagUrl: (genre) => `/festivals/genre/${genre.slug}`,
                onPress: () =>
                    !config?.onPress
                        ? router.push(`/festivals/${festival.slug}`)
                        : config.onPress(),
            };
        }) ?? []
    );
};

export const getDate = (dates?: ResultDate[]) => {
    const date = dates?.[0];

    if (date) {
        if (typeof date !== 'string') {
            return getFormattedDate(date.date);
        } else if (typeof date === 'string') {
            return getFormattedDate(date);
        }
    }

    return;
};

export const transformPastFestivalResults = (
    festivals: PastFestival[],
    router: NextRouter,
    config?: DataTransformConfig
): TileProps[] => {
    return (
        festivals?.map((festival: PastFestival) => {
            return {
                id: festival.id,
                type: 'festival',
                title: festival.name,
                slug: festival?.slug,
                shareUrl: `/festivals/${festival?.slug}`,
                summary: getDate(festival?.dates),
                image: getImage(festival.thumbnail) ?? undefined,
                website: undefined,
                compare: festival?.compare,
                inUserCrate: festival?.inUserCrate,
                size: config?.size ?? 'default',
                style: config?.style ?? 'box',
                genres: festival?.genres,
                getTagUrl: (genre) => `/festivals/genre/${genre.slug}`,
                onPress: () =>
                    !config?.onPress
                        ? router.push(`/festivals/${festival.slug}`)
                        : config.onPress(),
            };
        }) ?? []
    );
};

export const transformArtistResults = (
    artists: Artist[],
    router: NextRouter,
    config?: DataTransformConfig
): TileProps[] => {
    return (
        artists?.map((artist: Artist) => {
            return {
                id: artist.id,
                type: 'artist',
                title: artist.name,
                slug: artist?.slug,
                shareUrl: `/artists/${artist?.slug}`,
                image: artist?.imageUrl,
                spotifyUrl: artist?.spotifyProfile,
                size: config?.size ?? 'default',
                style: config?.style ?? 'box',
                festivals: artist?.festivals,
                getFestivalUrl: (festival) => `/festivals/${festival.slug}`,
                genres: artist?.genres,
                getTagUrl: (genre) => `/artists/genre/${genre.slug}`,
                onPress: () =>
                    !config?.onPress
                        ? router.push(`/artists/${artist.slug}`)
                        : config.onPress(),
            };
        }) ?? []
    );
};

export const transformPostResults = (
    posts: Post[],
    router: NextRouter,
    config?: DataTransformConfig
): TileProps[] => {
    return (
        posts?.map((post: Post) => {
            return {
                id: post.id,
                type: 'post',
                title: post.title,
                slug: post.slug,
                shareUrl: `/blog/${post?.slug ?? ''}`,
                subTitle: getFormattedDate(post?.published_at),
                summary: post.summary,
                image: getImage(post?.featuredImage) ?? undefined,
                onPress: () =>
                    !config?.onPress
                        ? router.push(`/blog/${post?.slug}`)
                        : config.onPress(),
            };
        }) ?? []
    );
};

export const transformMediaResults = (
    images: ResultImage[],
    config?: DataTransformConfig
): TileProps[] => {
    return (
        images?.map((image: ResultImage) => {
            const thumbnail = getImage(image) ?? undefined;
            const src = getImage(image, 'large') ?? undefined;
            const caption = image?.caption;

            return {
                id: image.id,
                type: 'media',
                summary: caption,
                image: thumbnail,
                onPress: () =>
                    config?.onPress?.({ id: image.id, src, caption }),
            };
        }) ?? []
    );
};
